<script>
import LandingPageLayout from "@/layouts/LandingPageLayout.vue";
import ImperativeDialog from "@/components/ImperativeDialog.vue";

import { apiLogin, apiResetPasswordApply, apiSetPassword } from "@/http";
import { notify } from "@/utils/notify";

export default {
  components: {
    LandingPageLayout,
    ImperativeDialog,
  },
  data() {
    return {
      // account | password
      formMode: "account",
      formState: {
        account: "",
        password: "",
      },
      passwordCreateFormState: {
        password: "",
      },
    };
  },
  methods: {
    async submitWithCheckAccountStep() {
      if (!this.formState.account) {
        return notify.warning("请先输入企微英文全称");
      }

      const res = await apiLogin({ email: this.formState.account });

      if (res.errorCode !== 1000) {
        return notify.error(res.errorMessage);
      }

      if (res.data.type === 1) {
        this.passwordCreationDialog?.open(
          async (context) => {
            if (!this.passwordCreateFormState.password) {
              return notify.warning("请先输入密码");
            }

            const res = await apiSetPassword({
              email: this.formState.account,
              password: this.passwordCreateFormState.password,
            });

            if (res.errorCode !== 1000) {
              return notify.error(res.errorMessage);
            }

            notify.success(res.errorMessage);
            context.close();

            this.passwordCreateFormState.password = "";
          },
          async (context) => {
            context.close();

            this.passwordCreateFormState.password = "";
          }
        );
        return;
      }

      if (res.data.type === 2) {
        this.formMode = "password";
        return;
      }
    },

    async submitWithCheckPasswordStep() {
      if (!this.formState.password) {
        return notify.warning("请先输入密码");
      }

      const res = await apiLogin({
        email: this.formState.account,
        password: this.formState.password,
      });

      if (res.errorCode !== 1000) {
        return notify.error(res.errorMessage);
      }

      localStorage.setItem("auth_token", res.data.access_token);
      notify.success(res.errorMessage);

      let redirectRoute = "/";
      if (this.$route.query.redirect) {
        redirectRoute = decodeURIComponent(this.$route.query.redirect);
      }

      this.$router.replace(redirectRoute);
    },

    handleConfirmLogin() {
      if (this.formMode === "account") {
        this.submitWithCheckAccountStep();
      } else {
        this.submitWithCheckPasswordStep();
      }
    },

    handleForgetPassword() {
      this.passwordForgetDialog?.open(async (context) => {
        const res = await apiResetPasswordApply({
          email: this.formState.account,
        });

        if (res.errorCode !== 1000) {
          return notify.error(res.errorMessage);
        }

        context.close();
        notify.success(res.errorMessage);

        this.formMode = "account";
      });
    },
  },
  mounted(){
    // this.passwordCreationDialog?.open()
  },
  computed: {
    passwordForgetDialog() {
      return this.$refs.passwordForgetDialog;
    },
    passwordCreationDialog() {
      return this.$refs.passwordCreationDialog;
    },
  },
};
</script>

<template>
  <LandingPageLayout title="共创杯·竞猜互动">
    <div class="form">
      <div class="input">
        <div class="input_label">登入</div>
        <input
          class="input_inner"
          placeholder="请先输入企微英文全称"
          v-model="formState.account"
          v-if="formMode === 'account'"
        />
        <input
          class="input_inner"
          placeholder="输入密码"
          type="password"
          v-model="formState.password"
          v-else
        />
      </div>
      <div v-if="formMode === 'password'" class="forget-password-wrap">
        <div class="forget-password" @click="handleForgetPassword">
          忘记密码
        </div>
      </div>
      <button class="login_button" @click="handleConfirmLogin">确认登录</button>

      <ImperativeDialog ref="passwordForgetDialog" title="忘记密码">
        <div style="text-align: center">向管理员发送重置密码申请</div>
      </ImperativeDialog>

      <ImperativeDialog ref="passwordCreationDialog" title="设置密码（6-8位，纯数字）">
        <input
          class="password_create_input"
          type="password"
          v-model="passwordCreateFormState.password"
          placeholder="请输入密码"
        />
      </ImperativeDialog>
    </div>
  </LandingPageLayout>
</template>

<style scoped lang="less">
.form {
  margin: auto;
  padding: 0px 32px;
  padding-top: 30px;
  box-sizing: border-box;

  .input {
    width: 100%;
    display: flex;
    flex-direction: column;

    .input_label {
      font-weight: 400;
      opacity: 0.62;
      color: #ffffff;
      text-align: left;
      margin-bottom: 17px;
    }

    .input_inner {
      height: 40px;
      border: none;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 8px;
      text-align: center;
      color: #ffffff;

      &::placeholder {
        color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .forget-password-wrap {
    text-align: right;
    .forget-password {
      margin-top: 20px;
      opacity: 0.62;
      color: #ffffff;
    }
  }

  .login_button {
    width: 100%;
    border: none;
    height: 40px;
    border: none;
    background-color: rgb(246, 117, 80);
    border-radius: 8px;
    text-align: center;
    color: #ffffff;
    margin-top: 16px;
  }
}

.password_create_input {
  height: 40px;
  border-radius: 8px;
  text-align: center;
  border: solid 1px #d2d2d2;
  margin: 25px 0px;
  width: 100%;
}
</style>
